import axios from 'axios';

// Configuración base de axios
// const api = axios.create({
//   baseURL: 'http://localhost:9997/api',
//   headers: {
//     'Content-Type': 'application/json',
//     'accept': '*/*'
//   }
// });

//const BASE_URL = 'http://localhost:9997/api';
const BASE_URL = 'http://161.132.38.144:9997/api';

const api = {
  getMedidores: async (params) => {
    try {
      const response = await axios.get(`${BASE_URL}/medidores/grid`, { params });
      return response.data;
    } catch (error) {
      console.error('Error fetching medidores:', error);
      throw error;
    }
  },

  getMedidorImages: async (idSocio, idMedidor) => {
    try {
      const response = await axios.get(`${BASE_URL}/medidors-images/search`, {
        params: { idsocio: idSocio, idMedidor }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching medidor images:', error);
      throw error;
    }
  },

  updateMedidor: async (id, data) => {
    try {
      const response = await axios.put(`${BASE_URL}/medidores/${id}`, data, {
        headers: { 'Content-Type': 'application/json' }
      });
      return response.data;
    } catch (error) {
      console.error('Error updating medidor:', error);
      throw error;
    }
  },

  deleteMedidor: async (id) => {
    try {
      const response = await axios.delete(`${BASE_URL}/medidores/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error deleting medidor:', error);
      throw error;
    }
  },

  createMedidor: async (data) => {
    try {
      const response = await axios.post(`${BASE_URL}/medidores`, data, {
        headers: { 'Content-Type': 'application/json' ,
        'accept': '*/*' }
      });
      return response.data;
    } catch (error) {
      console.error('Error updating medidor:', error);
      throw error;
    }
  },

  updateMedidor: async (id, data) => {
    try {
      const response = await axios.put(`${BASE_URL}/medidores/${id}`, data, {
        headers: { 'Content-Type': 'application/json' }
      });
      return response.data;
    } catch (error) {
      console.error('Error updating medidor:', error);
      throw error;
    }
  },

  deleteMedidor: async (id) => {
    try {
      const response = await axios.delete(`${BASE_URL}/medidores/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error deleting medidor:', error);
      throw error;
    }
  }
  
};

// Funciones para los diferentes endpoints
//export const createMedidor = (data) => api.post('/medidores', data);
//export const fetchMedidores = (params) => api.get('/medidores/grid', { params });
//export const updateMedidor = (id, data) => api.put(`/medidores/${id}`, data);
//export const deleteMedidor = (id) => api.delete(`/medidores/${id}`);
//export const fetchMedidorImages = (idsocio, idMedidor) => api.get('/medidors-images/search', { params: { idsocio, idMedidor } });

export default api;