import React from 'react';
import { Typography, Grid, Paper } from '@mui/material';

function Home() {
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 140,
              bgcolor: 'primary.main',
              color: 'white',
            }}
          >
            <Typography variant="h5" component="h2">
              150
            </Typography>
            <Typography variant="body2">New Orders</Typography>
          </Paper>
        </Grid>
        {/* Agrega más tarjetas de estadísticas aquí */}
      </Grid>
      {/* Agrega más componentes del dashboard aquí */}
    </div>
  );
}

export default Home;