import React from 'react';
import { List, ListItemButton, ListItemIcon, ListItemText, Collapse, Tooltip } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Link } from 'react-router-dom';

function AccordionMenu({ isCollapsed }) {
  const [openMedidores, setOpenMedidores] = React.useState(false);
  const [openRecibos, setOpenRecibos] = React.useState(false);

  const MenuItemContent = ({ icon, text, isSubmenu = false }) => (
    <>
      <ListItemIcon sx={{ minWidth: isCollapsed ? 'auto' : 56 }}>
        {isCollapsed ? <Tooltip title={text} placement="right">{icon}</Tooltip> : icon}
      </ListItemIcon>
      {!isCollapsed && <ListItemText primary={text} />}
    </>
  );

  return (
    <List>
      <ListItemButton component={Link} to="/home">
        <MenuItemContent icon={<DashboardIcon />} text="Dashboard" />
      </ListItemButton>

      <ListItemButton onClick={() => setOpenMedidores(!openMedidores)}>
        <MenuItemContent icon={<DashboardIcon />} text="Medidores" />
        {!isCollapsed && (openMedidores ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      <Collapse in={openMedidores && !isCollapsed} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} component={Link} to="/medidores/registro">
            <MenuItemContent icon={<DashboardIcon />} text="Registro de Medidores" isSubmenu />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} component={Link} to="/medidores/busqueda">
            <MenuItemContent icon={<DashboardIcon />} text="Búsqueda de Medidores" isSubmenu />
          </ListItemButton>
        </List>
      </Collapse>

      <ListItemButton onClick={() => setOpenRecibos(!openRecibos)}>
        <MenuItemContent icon={<ReceiptIcon />} text="Recibos" />
        {!isCollapsed && (openRecibos ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      <Collapse in={openRecibos && !isCollapsed} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} component={Link} to="/recibos/registro">
            <MenuItemContent icon={<ReceiptIcon />} text="Registro de Recibos" isSubmenu />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} component={Link} to="/recibos/buscar">
            <MenuItemContent icon={<ReceiptIcon />} text="Buscar Recibos" isSubmenu />
          </ListItemButton>
        </List>
      </Collapse>
    </List>
  );
}

export default AccordionMenu;