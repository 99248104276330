import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Grid, Snackbar, Box } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import api from '../api';

function RegistroMedidores() {
  const [formData, setFormData] = useState({
    idSocio: '',
    modelo: '',
    condicion: '',
    consumo: '',
    fechaInicio: '',
    fechaFinal: '',
    imagenes: [] // Guardar las imágenes en formato Base64
  });

  const [previewImages, setPreviewImages] = useState([]); // Guardar las URLs para vista previa
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleImageChange = (acceptedFiles) => {
    if (acceptedFiles.length > 3) {
      setSnackbarMessage('Solo puedes subir hasta 3 imágenes');
      setOpenSnackbar(true);
      return;
    }

    const imagenesArray = [];
    const previewArray = [];

    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        imagenesArray.push(reader.result.split(',')[1]); // Guardar la imagen codificada en Base64

        if (imagenesArray.length <= 3) {
          setFormData(prevState => ({
            ...prevState,
            imagenes: [...imagenesArray]
          }));
        }
      };

      // Generar vista previa de la imagen
      const previewUrl = URL.createObjectURL(file);
      previewArray.push(previewUrl);
    });

    setPreviewImages(previewArray); // Guardar las URLs para las vistas previas
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleImageChange,
    accept: 'image/*',
    maxFiles: 3
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // const response = await axios.post('http://localhost:9997/api/medidores', {
      //   ...formData,
      //   consumo: parseFloat(formData.consumo),
      //   fechaInicio: new Date(formData.fechaInicio).toISOString(),
      //   fechaFinal: new Date(formData.fechaFinal).toISOString()
      // }, {
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'accept': '*/*'
      //   }
      // });
      await api.createMedidor({
        ...formData,
        consumo: parseFloat(formData.consumo),
        fechaInicio: new Date(formData.fechaInicio).toISOString(),
        fechaFinal: new Date(formData.fechaFinal).toISOString()
      });
      setSnackbarMessage('Medidor registrado con éxito');
      setOpenSnackbar(true);
      setFormData({
        idSocio: '',
        modelo: '',
        condicion: '',
        consumo: '',
        fechaInicio: '',
        fechaFinal: '',
        imagenes: [] // Limpiar las imágenes
      });
      setPreviewImages([]); // Limpiar las vistas previas
    } catch (error) {
      console.error('Error al registrar el medidor:', error);
      setSnackbarMessage('Error al registrar el medidor');
      setOpenSnackbar(true);
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom>
        Registro de Medidores
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="ID Socio"
              name="idSocio"
              value={formData.idSocio}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Modelo"
              name="modelo"
              value={formData.modelo}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Condición"
              name="condicion"
              value={formData.condicion}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Consumo"
              name="consumo"
              type="number"
              value={formData.consumo}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Fecha Inicio"
              name="fechaInicio"
              type="date"
              value={formData.fechaInicio}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Fecha Final"
              name="fechaFinal"
              type="date"
              value={formData.fechaFinal}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              required
            />
          </Grid>

          {/* Componente para arrastrar y soltar imágenes */}
          <Grid item xs={12}>
            <Box
              {...getRootProps()}
              sx={{
                border: '2px dashed #3f51b5',
                padding: '20px',
                textAlign: 'center',
                borderRadius: '8px',
                cursor: 'pointer',
                backgroundColor: isDragActive ? '#f0f0f0' : '#fff'
              }}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <Typography>¡Suelta las imágenes aquí!</Typography>
              ) : (
                <Typography>Arrastra y suelta hasta 3 imágenes aquí, o haz clic para seleccionar</Typography>
              )}
            </Box>
          </Grid>

          {/* Mostrar la vista previa de las imágenes */}
          <Grid item xs={12}>
            {previewImages.length > 0 && (
              <Grid container spacing={2}>
                {previewImages.map((image, index) => (
                  <Grid item xs={4} key={index}>
                    <img
                      src={image}
                      alt={`preview ${index}`}
                      style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>

          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Registrar Medidor
            </Button>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
      />
    </Container>
  );
}

export default RegistroMedidores;
