import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Login from './components/Login';
import Home from './components/Home';
import ProtectedRoute from './components/ProtectedRoute';
import RegistroMedidores from './components/Medidores/RegistroMedidores';
import MedidoresGrid from './components/Medidores/MedidoresGrid';
import Layout from './components/Layout';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const theme = createTheme();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<ProtectedRoute />}>
            <Route element={<Layout />}>
              <Route path="/" element={<Navigate replace to="/home" />} />
              <Route path="/home" element={<Home />} />
              <Route path="/medidores/registro" element={<RegistroMedidores />} />
              <Route path="/medidores/busqueda" element={<MedidoresGrid />} />
              {/* Agrega aquí más rutas para otros componentes */}
            </Route>
          </Route>
          <Route path="*" element={<Navigate replace to="/login" />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;