import React, { useState } from 'react';
import { Box, CssBaseline, AppBar, Toolbar, Typography, IconButton, Drawer } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccordionMenu from './AccordionMenu';
import { Outlet } from 'react-router-dom';

const expandedWidth = 240;
const collapsedWidth = 60;

function Layout() {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        width: `calc(100% - ${isCollapsed ? collapsedWidth : expandedWidth}px)`,
        transition: (theme) => theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setIsCollapsed(!isCollapsed)}
            edge="start"
            sx={{ marginRight: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            AdminLTE 3
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: isCollapsed ? collapsedWidth : expandedWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: isCollapsed ? collapsedWidth : expandedWidth,
            transition: (theme) => theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
          },
        }}
      >
        <Toolbar />
        <AccordionMenu isCollapsed={isCollapsed} />
      </Drawer>
      <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: {
                sm: `calc(100% - ${isCollapsed ? collapsedWidth : expandedWidth}px)`,
                },
                marginLeft: isCollapsed ? 0 : 0,
                transition: (theme) =>
                theme.transitions.create(["margin", "width"], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
            }}
            >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}

export default Layout;