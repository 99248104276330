import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, Box } from '@mui/material';
import Slider from "react-slick";								 
import api from '../api';

const MedidoresGrid = () => {
  const [medidores, setMedidores] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [totalElements, setTotalElements] = useState(0);

  const [idSocio, setIdSocio] = useState('');
  const [fechaInicio, setFechaInicio] = useState('');
  const [fechaFinal, setFechaFinal] = useState('');

  const [selectedMedidor, setSelectedMedidor] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [images, setImages] = useState([]);  // Estado para almacenar las imágenes
																			   

  useEffect(() => {
    fetchMedidores(page, size);
  }, [page, size]);

  const fetchMedidores = async (page, size) => {
    try {
        const params = {
            idSocio: idSocio || undefined,
            fechaInicio: fechaInicio || undefined,
            fechaFinal: fechaFinal || undefined,
            page,
            size
          };

      const data = await api.getMedidores(params);
      setMedidores(data.content);
      setTotalElements(data.totalElements);
      //setMedidores(response.data.content);
      //setTotalElements(response.data.totalElements);
    } catch (error) {
      console.error('Error fetching medidores data:', error);
    }
  };

  const handleSearch = () => {
    setPage(0);
    fetchMedidores(0, size);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event.target.value, 10));
    setPage(0);
  };

																		   
  // Función para abrir el modal y cargar los datos del ítem seleccionado
  const handleOpenModal = async (medidor) => {
    setSelectedMedidor(medidor);
    await fetchMedidorImages(medidor.idSocio, medidor.id);
    setOpenModal(true);
  };

								  
  // Función para cerrar el modal
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedMedidor(null);
    setImages([]); // Limpiar las imágenes al cerrar el modal
  };

  // Función para obtener las imágenes asociadas al medidor

  const fetchMedidorImages = async (idSocio, idMedidor) => {
    try {
      const imagesData = await api.getMedidorImages(idSocio, idMedidor);
      console.log(imagesData);
      const imageUrls = imagesData.map(imagesData => `data:image/jpeg;base64,${imagesData.imagen}`);
      console.log(imageUrls);
      console.log(imageUrls.length);
      setImages([...new Set(imageUrls)]); // Add only unique images
    } catch (error) {
      console.error('Error fetching medidor images:', error);
    }
  };
  
  // Configuración para el carrusel
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  // Función para manejar los cambios en el formulario del modal
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedMedidor((prevMedidor) => ({
      ...prevMedidor,
      [name]: value
    }));
  };

 // Función para enviar los datos actualizados al backend													   
  const handleUpdate = async () => {
    try {
      await api.updateMedidor(selectedMedidor.id, selectedMedidor);  
      handleCloseModal();
      fetchMedidores(page, size);
    } catch (error) {
      console.error('Error updating medidor:', error);
    }
  };

  const handleDelete = async (medidorId) => {
    const confirmDelete = window.confirm(`¿Estás seguro de que deseas eliminar el medidor con ID ${medidorId}?`);
    if (confirmDelete) {
      try {
        await api.deleteMedidor(medidorId);
        fetchMedidores(page, size);
      } catch (error) {
        console.error('Error deleting medidor:', error);
      }
    }
  };

  return (
    <Paper>
      <Grid container spacing={2} style={{ padding: '16px' }}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            label="ID Socio"
            value={idSocio}
            onChange={(e) => setIdSocio(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            label="Fecha Inicio Desde"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={fechaInicio}
            onChange={(e) => setFechaInicio(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            label="Fecha Inicio Hasta"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={fechaFinal}
            onChange={(e) => setFechaFinal(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Button variant="contained" color="primary" onClick={handleSearch} fullWidth>
            Buscar
          </Button>
        </Grid>
      </Grid>

      <Box overflow="auto">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>ID Socio</TableCell>
                <TableCell>Modelo</TableCell>
                <TableCell>Condición</TableCell>
                <TableCell>Consumo</TableCell>
                <TableCell>Fecha Inicio</TableCell>
                <TableCell>Fecha Final</TableCell>
                <TableCell>Acciones</TableCell>
																									  
						  
						   
							
              </TableRow>
            </TableHead>
            <TableBody>
              {medidores.map((medidor) => (
                <TableRow key={medidor.id}>
                  <TableCell>{medidor.id}</TableCell>
                  <TableCell>{medidor.idSocio}</TableCell>
                  <TableCell>{medidor.modelo}</TableCell>
                  <TableCell>{medidor.condicion}</TableCell>
                  <TableCell>{medidor.consumo}</TableCell>
                  <TableCell>{new Date(medidor.fechaInicio).toLocaleDateString()}</TableCell>
                  <TableCell>{new Date(medidor.fechaFinal).toLocaleDateString()}</TableCell>
                  <TableCell>
                    <Button variant="outlined" color="primary" onClick={() => handleOpenModal(medidor)}>
                      Editar
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={() => handleDelete(medidor.id)} style={{ marginLeft: '8px' }}>
                      Eliminar
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <TablePagination
        component="div"
        count={totalElements}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={size}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />

										  
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Editar Medidor</DialogTitle>
        <DialogContent>
          <TextField
            label="ID Socio"
            name="idSocio"
            value={selectedMedidor?.idSocio || ''}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
            variant="outlined"
          />
          <TextField
            label="Modelo"
            name="modelo"
            value={selectedMedidor?.modelo || ''}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
            variant="outlined"
          />
          <TextField
            label="Condición"
            name="condicion"
            value={selectedMedidor?.condicion || ''}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
            variant="outlined"
          />
          <TextField
            label="Consumo"
            name="consumo"
            type="number"
            value={selectedMedidor?.consumo || ''}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
            variant="outlined"
          />
          <TextField
            label="Fecha Inicio"
            name="fechaInicio"
            type="datetime-local"
            value={selectedMedidor?.fechaInicio || ''}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />
          <TextField
            label="Fecha Final"
            name="fechaFinal"
            type="datetime-local"
            value={selectedMedidor?.fechaFinal || ''}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />

          {/* Carrusel de Imágenes */}
          {images.length >= 1 && (
            <div style={{ marginTop: '16px' }}>
                <h4>Imágenes Asociadas</h4>
                <Slider {...carouselSettings}>
                {(() => {
                    const imageElements = [];
                    for (let index = 0; index < images.length; index++) {
                        const image = images[index];
                        console.log("carrusel "+index);
                        imageElements.push(
                            <div key={index}>
                                <img src={image} alt={`Imagen ${index + 1}`} style={{ width: '100%' }} />
                            </div>
                        );
                        index++;
                    }
                    return imageElements;
                })()}
                </Slider>
            </div>
            )}	
				   
       </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleUpdate} color="primary">
            Guardar Cambios
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default MedidoresGrid;